import axios from 'axios';
import { setUser } from '../store/userSlice';

const apiUrl = process.env.REACT_APP_API_URL;

async function adminLogin (email, password, navigate, setError, dispatch) {
  const url = `${apiUrl}/admin/login`;
  const credentials = {email, password};
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  await axios.post(url, credentials, config)
    .then((response) => dispatch(setUser(response.data)))
    .then(() => navigate('/dashboard'))
    .catch(() => setError('Email ou mot de passe incorrect'));
}

async function adminLogout (dispatch) {
  dispatch(setUser(null));
}

export {adminLogin, adminLogout};