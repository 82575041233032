import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "./login";
import Dashboard from "./dashboard";
import Products from "./products";
import Admin from "./admin";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/dashboard',
    element: <Admin page={<Dashboard />} />
  },
  {
    path: '/products',
    element: <Admin page={<Products />} />
  },
]);
