import axios from 'axios';
import { toast, Bounce } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_URL;

function handelChangeSearchInput(e, products, setProducts) {
  setProducts(products.filter(product => product.id.toString().startsWith(e.target.value)));
};

async function index(setProducts, setAllProducts, setCircularProgress) {
  setCircularProgress(true);
  const url = `${apiUrl}/admin/products`;
  await axios.get(url)
    .then((response) => {
      setAllProducts(response.data);
      setProducts(response.data);
    })
    .then(() => setCircularProgress(false))
    .catch((err) => {
      console.log(err);
    });
}

function create(setOpenAddProduct) {
  setOpenAddProduct(true);
}

async function store(productsData, setProducts, setAllProducts, setCircularProgress, setOpenAddProduct, setErrorAddProduct) {
  const url = `${apiUrl}/admin/products`;
  const formData = new FormData();
  formData.append('categorie', productsData.categorie);
  formData.append('description', productsData.description);
  formData.append('price', productsData.price);
  formData.append('discount', productsData.discount);
  formData.append('image1', productsData.image1[0]);
  formData.append('image2', productsData.image2[0]);
  formData.append('image3', productsData.image3[0]);
  formData.append('image4', productsData.image4[0]);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  await axios.post(url, formData, config)
    .then(() => setErrorAddProduct(null))
    .then(() => setOpenAddProduct(false))
    .then(() => index(setProducts, setAllProducts, setCircularProgress))
    .then(() => {
      toast.success('le produit a bien été ajouté', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    })
    .catch((err) => {
      setErrorAddProduct(err.response.data.errors);
    });
}

async function show(id, setSelectedProduct) {
  const url = `${apiUrl}/admin/products/${id}`;
  await axios.get(url)
    .then((response) => {
      setSelectedProduct(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

function edit(id, setOpenUpdateProduct, setSelectedProduct) {
  setOpenUpdateProduct(true);
  show(id, setSelectedProduct);
}

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

async function update(productsData, setProducts, setAllProducts, setCircularProgress, setOpenUpdateProduct, setErrorUpdateProduct) {
  const url = `${apiUrl}/admin/products/${productsData.id}`;

  const data = {
    categorie: productsData.categorie,
    description: productsData.description,
    price: productsData.price,
    discount: productsData.discount,
  }
  if (productsData.image1 && productsData.image1[0]) {
    data['image1'] = await fileToBase64(productsData.image1[0]);
  }
  if (productsData.image2 && productsData.image2[0]) {
    data['image2'] = await fileToBase64(productsData.image2[0]);
  }
  if (productsData.image3 && productsData.image3[0]) {
    data['image3'] = await fileToBase64(productsData.image3[0]);
  }
  if (productsData.image4 && productsData.image4[0]) {
    data['image4'] = await fileToBase64(productsData.image4[0]);
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  await axios.put(url, data, config)
    .then(() => setErrorUpdateProduct(null))
    .then(() => setOpenUpdateProduct(false))
    .then(() => index(setProducts, setAllProducts, setCircularProgress))
    .then(() => {
      toast.success('le produit a bien été modifié', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    })
    .catch((err) => {
      setErrorUpdateProduct(err.response.data.errors);
    });
}

async function destroy(idProduct, setProducts, setAllProducts, setCircularProgress) {
  setCircularProgress(true);
  const url = `${apiUrl}/admin/products/${idProduct}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  await axios.delete(url, config)
    .then(() => index(setProducts, setAllProducts, setCircularProgress))
    .then(() => {
      toast.success('Le produit a bien été supprimé', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

export {handelChangeSearchInput, index, create, store, show, edit, update, destroy};