import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { IconButton, Tooltip } from '@mui/material';

function Tab({
  icon,
  title,
  link,
  isActive,
}) {
  const navigate = useNavigate();
  return (
    <Tooltip title={title} placement='right'>
      <div className='group flex'>
        <div className={`flex flex-col justify-center items-center ${isActive && 'bg-primary'} h-12`}>
          <IconButton onClick={() => navigate(link)}>
            {icon}
          </IconButton>
        </div>
        <div className={`group-hover:inline w-1 h-12 bg-primary ${isActive ? 'inline' : 'hidden'}`} />
      </div>
    </Tooltip>
  );
}

export default function Sidebar() {
  const tabs = [
    {
      icon: <DashboardOutlinedIcon style={{color: 'white'}} />,
      title: 'Tableau de bord',
      link: '/dashboard',
    },
    {
      icon: <Inventory2OutlinedIcon style={{color: 'white'}} />,
      title: 'Produits',
      link: '/products',
    },
  ]

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  return (
    <div className='w-12'>
      <div className='bg-[#333333] h-screen flex flex-col justify-center fixed'>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              icon={tab.icon}
              title={tab.title}
              link={tab.link}
              isActive={`/${lastSegment}` === tab.link}
            />
          );
        })}
      </div>
    </div>
  );
}
