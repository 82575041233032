import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Avatar, IconButton } from '@mui/material';
import DetailsProductTable from './table/detailsProductTable';

export default function DetailsProduct({
  setOpenDrawer,
  product
}) {
  return (
    <div>
      <div className='h-14 bg-white w-full border-b flex justify-between items-center pl-6 pr-2'>
        <div>
          <span className='font-bold text-3xl text-primary'>M</span>
          <span className='font-bold text-3xl'>y</span>
          <span className='font-bold text-3xl text-primary'>T</span>
          <span className='font-bold text-3xl'>iss</span>
          <span className='font-bold text-3xl text-primary'>B</span>
          <span className='font-bold text-3xl'>rode</span>
        </div>
        <div className='flex items-center'>
          <IconButton onClick={setOpenDrawer}>
            <Avatar sx={{backgroundColor: '#C00F0C'}}>
              <CloseOutlinedIcon />
            </Avatar>
          </IconButton>
        </div>
      </div>
      <div className='p-5'>
        <p className='font-poppins font-medium text-2xl mb-5'>Produit {product?.id} :</p>
        <DetailsProductTable product={product} />
      </div>
    </div>
  );
}
