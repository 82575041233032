import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from '@mui/material';
import { adminLogin } from './functions/login';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from './store/userSlice';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  return (
    !user && (
      <div className='h-screen w-screen flex flex-col justify-center items-center'>
        {error && (
          <div className='mb-5 w-[400px]'>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <div className='rounded-lg border w-[90%] sm:w-[400px] p-5 flex flex-col items-center bg-white shadow-sm'>
          <div className='pb-2 border-b w-full'>
            <span className='font-bold text-3xl text-primary'>M</span>
            <span className='font-bold text-3xl'>y</span>
            <span className='font-bold text-3xl text-primary'>T</span>
            <span className='font-bold text-3xl'>iss</span>
            <span className='font-bold text-3xl text-primary'>B</span>
            <span className='font-bold text-3xl'>rode</span>
          </div>
          <div className='w-full py-5 flex flex-col gap-3'>
            <label htmlFor='email'>
              <p className='font-poppins text-lg'>Email :</p>
              <input
                id='email'
                type='email'
                className='w-full outline-none border focus:border-primary rounded font-poppins text-lg p-2 placeholder'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label htmlFor='password'>
              <p className='font-poppins text-lg'>Mot de passe :</p>
              <input
                id='password'
                type='password'
                className='w-full outline-none border focus:border-primary rounded font-poppins text-lg p-2 placeholder'
                placeholder='Mot de passe'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div className='mt-1 w-full'>
              <Button
                variant='contained'
                sx={{backgroundColor: '#C00F0C', paddingY: '8px'}}
                onClick={() => adminLogin(email, password, navigate, setError, dispatch)}
              >
                Se connecter
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
