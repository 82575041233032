import React from 'react';

export default function Kpi({
  title,
  value
}) {
  return (
    <div className='bg-white border p-5 shadow-sm col-span-1 row-span-1 flex flex-col justify-between'>
      <p className='font-poppins text-xl'>{title}</p>
      <p className='font-poppins font-bold text-3xl'>{value}</p>
    </div>
  );
}
