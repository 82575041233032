import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Sidebar from './components/sidebar';
import Header from './components/header';
import ProductsTable from './components/table/productsTable';
import searchIcon from './assets/icons/search-normal.png';
import { create, destroy, handelChangeSearchInput, index, store, show, edit, update } from './functions/products';
import AddProductDialog from './components/dialog/addProduct';
import UpdateProductDialog from './components/dialog/updateProduct';
import DetailsProduct from './components/detailsProduct';

export default function Products() {
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [circularProgress, setCircularProgress] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState();

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [errorAddProduct, setErrorAddProduct] = useState(null);

  const [openUpdateProduct, setOpenUpdateProduct] = useState(false);
  const [errorUpdateProduct, setErrorUpdateProduct] = useState(null);

  useEffect(() => {
    index(setProducts, setAllProducts, setCircularProgress);
  }, []);
  return (
    <>
      <ToastContainer />
      <AddProductDialog
        onClose={() => setOpenAddProduct(false)}
        open={openAddProduct}
        onClickAddProduct={(productsData) => store(productsData, setProducts, setAllProducts, setCircularProgress, setOpenAddProduct, setErrorAddProduct)}
        setErrorAddproduct={setErrorAddProduct}
        errors={errorAddProduct}
      />
      <UpdateProductDialog
        onClose={() => setOpenUpdateProduct(false)}
        open={openUpdateProduct}
        product={selectedProduct}
        onClickUpdateProduct={(productsData) => update(productsData, setProducts, setAllProducts, setCircularProgress, setOpenUpdateProduct, setErrorUpdateProduct)}
        setErrorUpdateproduct={setErrorUpdateProduct}
        errors={errorUpdateProduct}
      />
      <Drawer
        open={openDrawer}
        anchor='left'
        onClose={() => setOpenDrawer(false)}
      >
        <div className='w-[500px]'>
          <DetailsProduct
            product={selectedProduct}
            setOpenDrawer={() => setOpenDrawer(false)}
          />
        </div>
      </Drawer>
      <div className='flex'>
        <Sidebar />
        <div className='flex-1 flex flex-col'>
          <Header />
          <div className='p-5 mt-2'>
            <div className='flex justify-between items-center mb-5'>
              <p className='font-poppins font-medium text-2xl'>Tous les produits</p>
              <Button
                variant='contained'
                sx={{backgroundColor: '#C00F0C'}}
                onClick={() => create(setOpenAddProduct)}
              >
                + Ajouter un nouveau produit
              </Button>
            </div>
            <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
              <img src={searchIcon} alt='searchIcon'/>
              <input
                type='search'
                placeholder='Chercher par ID'
                onChange={(e) => handelChangeSearchInput(e, allProducts, setProducts)}
                className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
              />
            </div>
            <ProductsTable
              header={['Id-produit', 'Catégorie', 'déscription', 'Prix', 'PrixOriginale', 'Date', 'Actions']}
              data={products}
              circularProgress={circularProgress}
              showProduct={(id) => {
                show(id, setSelectedProduct);
                setOpenDrawer(true);
              }}
              updateProduct={(id) => edit(id, setOpenUpdateProduct, setSelectedProduct)}
              destroyProduct={(id) => destroy(id, setProducts, setAllProducts, setCircularProgress)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
