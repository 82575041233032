import React from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import productIdIcon from '../../assets/icons/productIdIcon.png';

function ProductsTable({
  header,
  data,
  circularProgress,
  showProduct,
  updateProduct,
  destroyProduct
}) {
  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th 
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        {circularProgress
        ? (
          <tbody>
            <tr>
              <td colSpan={7}>
                <div className='w-full flex justify-center items-center my-5'>
                  <CircularProgress size={20} sx={{color: '#C00F0C'}}/>
                </div>
              </td>
            </tr>
          </tbody>
        )
        : (
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                  <td>
                    <div className='flex items-center'>
                      <img src={productIdIcon} alt='productId'/>
                      <span className='font-poppins font-normal text-sm/[16px] ml-3 max-w-14 break-words'>{item.id}</span>
                    </div>
                  </td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{item.categorie}</td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center'>{item.description}</td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center'>{item.price}</td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center'>{item.discount}</td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</td>
                  <td className='py-5 text-center'>
                    <Tooltip title='Visualiser'>
                      <IconButton onClick={() => showProduct(item.id)}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Modifier'>
                      <IconButton onClick={() => updateProduct(item.id)}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Supprimer'>
                      <IconButton onClick={() => destroyProduct(item.id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )
      }
      </table>
      {data.length === 0 && !circularProgress && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px]'>Aucun produits</div>
      )}
    </>
  );
}

export default ProductsTable;