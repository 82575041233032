import React from 'react';

function DetailsProductTable({
  product,
}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  return (
    <>
      <div className='border shadow p-2 mb-5'>
        <p className='mb-2'>Image 1:</p>
        <img src={`${backendUrl}/storage/products/${product?.image1}`} alt='1' />
      </div>
      <div className='border shadow p-2 mb-5'>
        <p className='mb-2'>Image 2:</p>
        <img src={`${backendUrl}/storage/products/${product?.image2}`} alt='2' />
      </div>
      <div className='border shadow p-2 mb-5'>
        <p className='mb-2'>Image 3:</p>
        <img src={`${backendUrl}/storage/products/${product?.image3}`} alt='3' />
      </div>
      <div className='border shadow p-2 mb-5'>
        <p className='mb-2'>Image 4:</p>
        <img src={`${backendUrl}/storage/products/${product?.image4}`} alt='4' />
      </div>
    </>
  );
}

export default DetailsProductTable;