import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { adminLogout } from '../functions/login';
import { useDispatch } from 'react-redux';

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <div className='h-14 bg-white w-full border-b flex justify-between items-center pl-6 pr-2'>
      <div>
        <span className='font-bold text-3xl text-primary'>M</span>
        <span className='font-bold text-3xl'>y</span>
        <span className='font-bold text-3xl text-primary'>T</span>
        <span className='font-bold text-3xl'>iss</span>
        <span className='font-bold text-3xl text-primary'>B</span>
        <span className='font-bold text-3xl'>rode</span>
      </div>
      <div className='flex items-center'>
        <IconButton onClick={handleClickMenu}>
          <Avatar sx={{ bgcolor: '#C00F0C' }}>B</Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                minWidth: '100px',
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => adminLogout(dispatch)}>
            Se déconnecter
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
