import React from 'react';
import Sidebar from './components/sidebar';
import Header from './components/header';
import Kpi from './components/kpi';
import { LineChart } from '@mui/x-charts';

export default function Dashboard() {
  return (
    <div className='lg:h-screen w-screen flex'>
      <Sidebar />
      <div className='flex flex-col flex-1'>
        <Header />
        <div className='flex flex-col lg:flex-row gap-4 p-5 bg-[#fcfcfc]'>
          <div className='lg:w-2/5 bg-white border flex flex-col p-5'>
            <p className='mb-5 font-poppins text-xl'>Indicateurs de performances :</p>
            <div className='grid grid-col-2 gap-4'>
              <div className='col-span-1 grid grid-rows-3 gap-4'>
                <Kpi title='Taux de Conversion' value='0 %' />
                <Kpi title='Valeur moyenne des commandes' value='0 DH' />
                <Kpi title='Revenu par visiteur' value='0 DH' />
              </div>
              <div className='col-span-1 col-start-2 grid grid-rows-3 gap-4'>
                <Kpi title='Nombre total de visiteurs' value='0' />
                <Kpi title={'Chiffre d\'affaires total'} value='0 DH' />
                <Kpi title='Nombre total de commandes' value='0' />
              </div>
            </div>
          </div>
          <div className='h-[500px] lg:flex-1 lg:h-full lg:w-3/5 bg-white border p-5 flex flex-col pb-5'>
            <p className='mb-5 font-poppins text-xl'>Nombre de visiteurs par mois :</p>
            <div className='border shadow h-full'>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10, 12] }]}
                series={[
                  {
                    data: [20, 55, 20, 85, 15, 50, 5],
                  },
                ]}
                grid={{ vertical: false, horizontal: true }}
                colors={['#C00F0C']}
                sx={{
                  '& .MuiLineElement-root': {
                    stroke: '#000',
                    strokeWidth: 1
                  },
                  '& .MuiMarkElement-root': {
                    stroke: '#000',
                    strokeWidth: 1,
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
