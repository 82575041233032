import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, MenuItem, styled, TextField, ThemeProvider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function AddProductDialog({
  onClose,
  open,
  onClickAddProduct,
  setErrorAddproduct,
  errors,
}) {
  const [categorie, setCategorie] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [image1, setImage1] = useState('Image 1');
  const [image2, setImage2] = useState('Image 2');
  const [image3, setImage3] = useState('Image 3');
  const [image4, setImage4] = useState('Image 4');

  const categories = [
    {label: 'Nappe', value: 'nappe'},
    {label: 'Tissus', value: 'tissus'}
  ];

  useEffect(() => {
    setCategorie('');
    setDescription('');
    setPrice('');
    setDiscount('');
    setImage1('Image 1');
    setImage2('Image 2');
    setImage3('Image 3');
    setImage4('Image 4');
    setErrorAddproduct(null);
  }, [open, setErrorAddproduct]);

  useEffect(() => {
    if (image1[0]?.name == null) {
      setImage1('Image 1');
    }
  }, [image1]);

  useEffect(() => {
    if (image2[0]?.name == null) {
      setImage2('Image 2');
    }
  }, [image2]);

  useEffect(() => {
    if (image3[0]?.name == null) {
      setImage3('Image 3');
    }
  }, [image3]);

  useEffect(() => {
    if (image4[0]?.name == null) {
      setImage4('Image 4');
    }
  }, [image4]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>Ajouter un nouveau produit:</DialogTitle>
        <Grid container>
          {/* catégorie */}
          <Grid item xs={12}>
            <TextField
              id="categorie"
              label='Catégorie'
              variant="outlined"
              margin='dense'
              value={categorie}
              onChange={(e) => setCategorie(e.target.value)}
              error={errors && errors.categorie}
              helperText={errors && errors.categorie ? errors.categorie : ''}
              fullWidth
              select
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container>
          {/* description */}
          <Grid item xs={12}>
            <TextField
              id="description"
              type='text'
              label='Déscription'
              variant="outlined"
              margin='dense'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={errors && errors.description}
              helperText={errors && errors.description ? errors.description : ''}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* price */}
          <Grid item xs={6}>
            <TextField
              id="price"
              type='number'
              label='Prix'
              variant="outlined"
              margin='dense'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              error={errors && errors.price}
              helperText={errors && errors.price ? errors.price : ''}
              fullWidth
            />
          </Grid>
          {/* discount */}
          <Grid item xs={6}>
            <TextField
              id="discount"
              type='number'
              label='PrixOriginale'
              variant="outlined"
              margin='dense'
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              error={errors && errors.discount}
              helperText={errors && errors.discount ? errors.discount : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          {/* image 1 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image1 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              {image1[0]?.name ? image1[0].name : 'Image 1'}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage1(e.target.files)}
              />
            </Button>
            {errors && errors.image1 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image1}</p>)}
          </Grid>
          {/* image 2 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image2 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              {image2[0]?.name ? image2[0].name : 'Image 2'}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage2(e.target.files)}
              />
            </Button>
            {errors && errors.image2 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image2}</p>)}
          </Grid>
        </Grid>
        <Grid container>
          {/* image 3 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image3 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              {image3[0]?.name ? image3[0].name : 'Image 3'}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage3(e.target.files)}
              />
            </Button>
            {errors && errors.image3 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image3}</p>)}
          </Grid>
          {/* image 4 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image4 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              {image4[0]?.name ? image4[0].name : 'Image 4'}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage4(e.target.files)}
              />
            </Button>
            {errors && errors.image4 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image4}</p>)}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              sx={{backgroundColor: '#C00F0C'}}
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{backgroundColor: '#C00F0C'}}
              variant='contained'
              size='large'
              onClick={() => onClickAddProduct({
                categorie,
                description,
                price,
                discount,
                image1,
                image2,
                image3,
                image4
              })}
              fullWidth
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});