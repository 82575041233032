import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from './store/userSlice';
import { useNavigate } from 'react-router-dom';

export default function Admin({
  page
}) {
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <>
      {user && page}
    </>
  );
}
