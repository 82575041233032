import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, MenuItem, styled, TextField, ThemeProvider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function UpdateProductDialog({
  onClose,
  open,
  onClickUpdateProduct,
  product,
  setErrorUpdateproduct,
  errors,
}) {
  const [categorie, setCategorie] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');

  const categories = [
    {label: 'Nappe', value: 'nappe'},
    {label: 'Tissus', value: 'tissus'}
  ];

  useEffect(() => {
    if (product) {
      setCategorie(product.categorie || '');
      setDescription(product.description || '');
      setPrice(product.price || '');
      setDiscount(product.discount || '');
      setImage1(product.image1 || '');
      setImage2(product.image2 || '');
      setImage3(product.image3 || '');
      setImage4(product.image4 || '');
    }
    setErrorUpdateproduct(null);
  }, [open, product, setErrorUpdateproduct]);

  useEffect(() => {
    if (!image1 || image1[0]?.name == null) {
      setImage1(product?.image1);
    }
  }, [image1, product]);

  useEffect(() => {
    if (!image2 || image2[0]?.name == null) {
      setImage2(product?.image2);
    }
  }, [image2, product]);

  useEffect(() => {
    if (!image3 || image3[0]?.name == null) {
      setImage3(product?.image3);
    }
  }, [image3, product]);

  useEffect(() => {
    if (!image4 || image4[0]?.name == null) {
      setImage4(product?.image4);
    }
  }, [image4, product]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>Ajouter un nouveau produit:</DialogTitle>
        <Grid container>
          {/* catégorie */}
          <Grid item xs={12}>
            <TextField
              id="categorie"
              label='Catégorie'
              variant="outlined"
              margin='dense'
              value={categorie}
              onChange={(e) => setCategorie(e.target.value)}
              error={errors && errors.categorie}
              helperText={errors && errors.categorie ? errors.categorie : ''}
              fullWidth
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container>
          {/* description */}
          <Grid item xs={12}>
            <TextField
              id="description"
              type='text'
              label='Déscription'
              variant="outlined"
              margin='dense'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={errors && errors.description}
              helperText={errors && errors.description ? errors.description : ''}
              fullWidth
              multiline
              rows={3}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* price */}
          <Grid item xs={6}>
            <TextField
              id="price"
              type='number'
              label='Prix'
              variant="outlined"
              margin='dense'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              error={errors && errors.price}
              helperText={errors && errors.price ? errors.price : ''}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* discount */}
          <Grid item xs={6}>
            <TextField
              id="discount"
              type='number'
              label='PrixOriginale'
              variant="outlined"
              margin='dense'
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              error={errors && errors.discount}
              helperText={errors && errors.discount ? errors.discount : ''}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          {/* image 1 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image1 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              {/* Image1: {product.image1} */}
              Image1 {typeof image1 === 'string' ? image1 : image1 && image1[0]?.name ? image1[0]?.name : ''}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage1(e.target.files)}
              />
            </Button>
            {errors && errors.image1 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image1}</p>)}
          </Grid>
          {/* image 2 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image2 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              Image2 {typeof image2 === 'string' ? image2 : image2 && image2[0]?.name ? image2[0]?.name : ''}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage2(e.target.files)}
              />
            </Button>
            {errors && errors.image2 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image2}</p>)}
          </Grid>
        </Grid>
        <Grid container>
          {/* image 3 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image3 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              Image3 {typeof image3 === 'string' ? image3 : image3 && image3[0]?.name ? image3[0]?.name : ''}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage3(e.target.files)}
              />
            </Button>
            {errors && errors.image3 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image3}</p>)}
          </Grid>
          {/* image 4 */}
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              sx={{borderColor: errors && errors.image4 ? '#d32f2f' : 'rgba(0, 0, 0, 0.25)', width: '100%', paddingY: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
            >
              Image4 {typeof image4 === 'string' ? image4 : image4 && image4[0]?.name ? image4[0]?.name : ''}
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage4(e.target.files)}
              />
            </Button>
            {errors && errors.image4 && (<p className='text-[#d32f2f] text-xs pl-3 pt-1'>{errors.image4}</p>)}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              sx={{backgroundColor: '#C00F0C'}}
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{backgroundColor: '#C00F0C'}}
              variant='contained'
              size='large'
              onClick={() => onClickUpdateProduct({
                id: product.id,
                categorie,
                description,
                price,
                discount,
                image1: typeof image1 === 'string' || (image1 instanceof FileList && image1.length === 0) ? null : image1,
                image2: typeof image2 === 'string' || (image2 instanceof FileList && image2.length === 0) ? null : image2,
                image3: typeof image3 === 'string' || (image3 instanceof FileList && image3.length === 0) ? null : image3,
                image4: typeof image4 === 'string' || (image4 instanceof FileList && image4.length === 0) ? null : image4
              })}
              fullWidth
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});